import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { PayService } from '@pay/pay/pay.service';

@Injectable({
  providedIn: 'root'
})
export class EmbedGuard implements CanActivate {
  constructor(private payService: PayService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // clear local storage pay data on
    // every entry of embed components
    this.payService.clearLocalStoragePayData();

    return true;
  }
}
