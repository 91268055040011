import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.css']
})
export class CheckBoxComponent implements OnInit {
  @Output() counterEmitter = new EventEmitter<number>();
  @Input() counter = 0;
  @Input() upperLimit = Infinity;
  @Input() lowerLimit = 0;
  isChecked: boolean;

  constructor() {}

  ngOnInit() {}
  incrementCounterIfChecked() {
    if (this.applyUpperLimit()) {
      return;
    }
    this.counter += 1;
    this.emitCounter();
  }
  decrementCounterIfChecked() {
    if (this.applyLowerLimit()) {
      return;
    }
    this.counter -= 1;
    this.emitCounter();
  }

  checkValue(event: any) {
    console.log(event);
    if (event === 'yes') {
      this.incrementCounterIfChecked();
    } else if (event === 'no') {
      this.decrementCounterIfChecked();
    }
  }

  applyUpperLimit() {
    if (this.counter >= 2) {
      return true;
    }
  }

  applyLowerLimit() {
    if (this.counter <= 1) {
      return true;
    }
  }

  emitCounter() {
    this.counterEmitter.emit(this.counter);
  }
}
