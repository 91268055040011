import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Page404Component } from './shared/components/page404/page404.component';
import { AuthGuard } from './core/auth/auth.guard';
import { EmbedGuard } from './features/embed/embed/embed.guard';

declare type myCustomRoutes = Routes; // This line is because of CompoDoc
// CompoDoc documentation needs only 1 variable to be declared as "Routes" type in the whole file in order for it to work

const appRoutes: myCustomRoutes = [
  {
    path: 'community',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/community/community.module').then(
        m => m.CommunityModule
      )
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/auth/auth.module').then(m => m.AuthModule)
  },
  { path: '404', component: Page404Component },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: '**', redirectTo: '404', pathMatch: 'full' }
];

const routes: Routes = [
  {
    path: 'booksession',
    loadChildren: () =>
      import('./features/community/community.module').then(
        m => m.CommunityModule
      )
  },
  { path: '', redirectTo: 'i', pathMatch: 'full' },
  { path: 'i', children: [...appRoutes] },
  { path: 'w', children: [...appRoutes] },
  {
    path: 'embed',
    loadChildren: () =>
      import('./features/embed/embed.module').then(m => m.EmbedModule),
    canActivate: [EmbedGuard]
  },
  { path: '404', component: Page404Component },
  { path: '**', redirectTo: '404', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
