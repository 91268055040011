import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseRes } from 'src/app/shared/interfaces/response';
import { map } from 'rxjs/operators';

// import { JwtService } from './jwt.service';
// import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient // private jwtService: JwtService
  ) {}

  get<T>(
    /* Return the whole response to handle server-side paginated response  */
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<T> {
    return this.http
      .get<BaseRes<T>>(`${environment.BaseUrl}${path}`, {
        params
      })
      .pipe(map(el => el.data));
  }

  getPaginated<T>(
    /* Return the whole response to handle server-side paginated response  */
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<BaseRes<T>> {
    return this.http.get<BaseRes<T>>(`${environment.BaseUrl}${path}`, {
      params
    });
  }

  post<T>(path: string, body: object = {}): Observable<any> {
    return this.http
      .post<BaseRes<T>>(`${environment.BaseUrl}${path}`, body)
      .pipe(map(el => el.data));
  }

  postForPaymentCore(path: string, body: object = {}): Observable<string> {
    return this.http.post(`${environment.PaymentCoreUrl}${path}`, body, {
      responseType: 'text'
    });
  }
}
