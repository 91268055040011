import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { XpayService } from '../../../features/community/shared/xpay.service';
import { AuthService } from '../../../core/auth/auth.service';
import {
  Community,
  CommunityModuleMap
} from 'src/app/shared/interfaces/community';
import { featuresMap } from '../../../shared/interfaces/community';
import { User } from 'src/app/shared/interfaces/user';

@Component({
  selector: 'app-community-header',
  templateUrl: './community-header.component.html',
  styleUrls: ['./community-header.component.scss']
})
export class CommunityHeaderComponent implements OnInit {
  communityModuleMap: CommunityModuleMap = {
    Bill: {
      routerLink: 'bills',
      title: 'shared.tab.bills.title'
    },
    Subscription: {
      routerLink: 'subscriptions',
      title: 'shared.tab.subscriptions.title'
    },
    Event: {
      routerLink: 'events',
      title: 'shared.tab.events.title'
    },
    Training: {
      routerLink: 'trainings',
      title: 'shared.tab.trainings.title'
    },
    // Academy: {
    //   routerLink: 'sports',
    //   title: 'shared.tab.academy.title'
    // },
    Sport: {
      routerLink: 'sports',
      title: 'shared.tab.sport.title'
    },
    Course: {
      routerLink: 'courses',
      title: 'shared.tab.course.title'
    },
    Service: {
      routerLink: 'services',
      title: 'shared.tab.services.title'
    },
    Product: {
      routerLink: 'products',
      title: 'shared.tab.products.title'
    }
  };

  community$: Observable<Community>;

  tabHeadName: string;
  showTabset = true;
  isUserAuthed$: Observable<User>;
  communityData: Community = null;

  ngUnsubscribe = new Subject();

  constructor(
    private communityService: XpayService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.isUserAuthed$ = this.authService.currentUser$;

    this.community$ = this.communityService.communityInfo$.pipe(
      filter(community => community !== null),
      distinctUntilChanged(),
      map((community: Community) => {
        const mappedModules = community.app_modules_to_see.map(
          el => this.communityModuleMap[el]
        );
        community.mappedModules = mappedModules;

        return community;
      })
    );

    this.getCurrentActiveRoute();
  }

  getCurrentActiveRoute() {
    // ex. ["i", "community", "2", "bills"]
    const segments = this.router.url.split('/');
    segments.shift();

    if (
      segments.length === 4 &&
      Array.from(featuresMap.keys()).includes(segments[3])
    ) {
      const communityModule = featuresMap.get(segments[3]);
      this.tabHeadName = this.communityModuleMap[communityModule].title;
    }
  }

  changeCurrentActiveRoute(moduleTitle: string) {
    this.tabHeadName = moduleTitle;
  }
}
