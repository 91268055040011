import {
  NgModule,
  ErrorHandler,
  APP_INITIALIZER,
  Provider
} from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { ToastrModule } from 'ngx-toastr';
import { Angulartics2Module } from 'angulartics2';

import { environment } from 'src/environments/environment';

import { GlobalErrorHandler } from './error-handler/global-error-handler';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { ServerErrorInterceptor } from './interceptors/server-error.interceptor';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import * as Sentry from '@sentry/angular';

const providers: Provider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ServerErrorInterceptor,
    multi: true
  },
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingInterceptor,
    multi: true
  },
  // Always add the "ErrorHandler" as the last element in the array
  {
    provide: ErrorHandler,
    useClass: GlobalErrorHandler
  }
];

if (environment.disableGlobalErrorHandler) {
  providers.pop();
}

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,

    ToastrModule.forRoot(),
    Angulartics2Module.forRoot({ developerMode: !environment.production })
  ],
  exports: [Angulartics2Module],
  providers
})
export class CoreModule {}
