import { Community } from './community';
import { AmountBreakdown } from './amount-breakdown';
import { CustomField } from './custom-field';
import { PaymentMethodType } from './payment-method';

/*
  every interface (model interface in our case) should address directly the object which it will be consumed
  note: it's not necessary but it will make the process of consuming our objects much easier
*/

export interface MappedEvent {
  mappedPaymentMethods?: PaymentMethodType[];
}

export interface Event extends MappedEvent {
  community: Community;
  cover: string;
  custom_fields?: CustomField[];
  date: string;
  end_date: string;
  description: string;
  disclaimer: string;
  has_shipping_data: boolean;
  id: string;
  is_active: boolean;
  is_public: boolean;
  allow_recurring_payments: boolean;
  verify_mobile_number: boolean;
  location: string;
  location_map: string;
  name: string;
  tickets: Ticket[];
  training: boolean; // set manually
  payment_methods: string[];
  terms_and_condition: string;
}

export interface Ticket {
  available_quantity: number;
  expiration_time: Date;
  id: number;
  is_active: boolean;
  name: string;
  quantity: number;
  total: AmountBreakdown;
  limit_per_user?: number;
  group?: string;
  limit?: number;
}

export const BaseEventUrlMap = {
  event: 'events',
  product: 'products',
  service: 'services',
  training: 'trainings'
};
