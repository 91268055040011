/**
 * @author Karim Sheif
 */

import {
  Directive,
  ElementRef,
  Renderer2,
  OnInit,
  OnDestroy,
  Input
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

import { Subscription } from 'rxjs';

@Directive({
  selector: '[appRtl]'
})
export class RtlDirective implements OnInit, OnDestroy {
  private rtlSubscription: Subscription;
  activeLang: string;

  // tslint:disable-next-line: variable-name
  private _rtlClass: string[] | string = [];
  // tslint:disable-next-line: variable-name
  private _ltrClass: string[] | string = [];

  @Input('rtlClass')
  set rtlClass(rtlClasses: string[] | string) {
    this._rtlClass = rtlClasses;
  }

  @Input('ltrClass')
  set ltrClass(ltrClasses: string[] | string) {
    this._ltrClass = ltrClasses;
  }

  constructor(
    private renderer: Renderer2,
    private el: ElementRef<HTMLElement>,
    private translateService: TranslocoService
  ) {}

  ngOnInit() {
    // Initial toggle RTL normal class and bs class
    this.activeLang = this.translateService.getActiveLang();
    this.toggleRtlClass(this.activeLang);
    this.toggleRtlBsClass(this.activeLang);

    // Listener toggle RTL normal class and bs class
    this.rtlSubscription = this.translateService.langChanges$.subscribe(
      lang => {
        this.toggleRtlClass(lang);
        this.toggleRtlBsClass(lang);
      }
    );
  }

  ngOnDestroy() {
    if (this.rtlSubscription) {
      this.rtlSubscription.unsubscribe();
    }
  }

  toggleRtlClass(lang: string) {
    if (lang === 'ar') {
      this.renderer.addClass(this.el.nativeElement, 'rtl');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'rtl');
    }
  }

  toggleRtlBsClass(lang: string) {
    if (lang === 'ar') {
      this.addBsClass(this._rtlClass);
      this.removeBsClass(this._ltrClass);
    } else {
      this.addBsClass(this._ltrClass);
      this.removeBsClass(this._rtlClass);
    }
  }

  removeBsClass(classes: string[] | string) {
    if (Array.isArray(classes)) {
      classes.forEach(el =>
        this.renderer.removeClass(this.el.nativeElement, el)
      );
    } else {
      this.renderer.removeClass(this.el.nativeElement, classes);
    }
  }

  addBsClass(classes: string[] | string) {
    if (Array.isArray(classes)) {
      classes.forEach(el => this.renderer.addClass(this.el.nativeElement, el));
    } else {
      this.renderer.addClass(this.el.nativeElement, classes);
    }
  }
}
