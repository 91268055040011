import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-w-layout',
  templateUrl: './w-layout.component.html',
  styleUrls: ['./w-layout.component.scss']
})
export class WLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
