import { BillingInfo } from './billingInfo';
import { CartInfo } from './cartInfo';
import { CustomFieldPayload } from './custom-field';

export enum PayType {
  BILL = 'bill',
  EVENT = 'event',
  ACADEMY = 'academy',
  TEAM = 'team',
  MASYAF = 'masyaf',
  DIRECTORDER = 'directOrder',
  VARIABLEAMOUNT = 'variableAmount',
  TRAINING = 'training',
  SERVICE = 'service',
  PRODUCT = 'product'
}

export type PayUrlMap = {
  [x in PayType]: string;
};

export type PayUsingTypes =
  | 'card'
  | 'cash'
  | 'kiosk'
  | 'meeza/digital'
  | 'valu'
  | 'installment'
  | 'fawry';

export interface PayPayload extends CartInfo {
  billing_data: BillingInfo;
  community_id: string;
  pay_using: PayUsingTypes;
  installment_period?: number;
  is_recurring?: boolean;
  promocode_id?: number;
  custom_fields?: CustomFieldPayload[];
  source: string | null;
  language: string | null;
}
