import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private toastrService: ToastrService) {}

  showSuccess(message: string): void {
    this.toastrService.success(message);
  }

  showError(message: string): void {
    const errMessage = message.split('\n').join('<br>');

    this.toastrService.error(errMessage, 'The following Error occured', {
      onActivateTick: true,
      enableHtml: true
    });
  }
}
