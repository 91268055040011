import { Injectable } from '@angular/core';

import { storageFactory } from 'storage-factory';

import { WebStorage } from './base-storage';

const LOCAL_STORAGE = storageFactory(() => localStorage);

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService extends WebStorage {
  constructor() {
    super();
  }

  setItem(key: string, value: any) {
    LOCAL_STORAGE.setItem(`${this.APP_PREFIX}${key}`, JSON.stringify(value));
  }

  getItem(key: string) {
    return JSON.parse(LOCAL_STORAGE.getItem(`${this.APP_PREFIX}${key}`));
  }

  removeItem(key: string) {
    LOCAL_STORAGE.removeItem(`${this.APP_PREFIX}${key}`);
  }
}
