import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { User, Membership } from '@shared/interfaces/user';
import { AuthenticationType } from '@shared/interfaces/Authentication';
import { LocalStorageService } from '@core/web-storage/local-storage.service';
import { ApiService } from '@core/api/api.service';

export interface MembershipLogin {
  membershipId: string;
  communityId: string;
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private sendUrl = 'users/otp/send/';
  private verifyUrl = 'users/otp/verify/';

  private currentUser: BehaviorSubject<User>;
  currentUser$: Observable<User>;

  private authType = new BehaviorSubject<AuthenticationType>(null);
  authType$ = this.authType.asObservable();

  // model for membershipid and community id
  // that is used by the user to login (community login process = OTP)
  private membership = new BehaviorSubject<MembershipLogin>(null);
  membership$ = this.membership.asObservable();

  // model for user memberships
  // that is used to get available Memberships for the user at any time
  private userMemberships = new BehaviorSubject<Membership[]>(null);
  userMemberships$ = this.userMemberships.asObservable();

  constructor(
    private localStorageService: LocalStorageService,
    private apiService: ApiService
  ) {
    this.currentUser = new BehaviorSubject<User>(
      localStorageService.getItem('token')
    );
    this.currentUser$ = this.currentUser.asObservable();
  }

  // methods for userMemberships model
  changeUserMemberships(memberships: Membership[]) {
    console.log('updating user memberships...');

    this.userMemberships.next(memberships);
  }

  public get currentUserMemberships(): Membership[] {
    return this.userMemberships.value;
  }

  // methods for authType model
  public get authentication(): AuthenticationType {
    return this.authType.value;
  }

  changeAuthProcess(authType: AuthenticationType) {
    this.authType.next(authType);
  }

  // methods for currentUser state model

  public get currentUserValue(): User {
    return this.currentUser.value;
  }

  logout() {
    this.localStorageService.removeItem('token');
    this.currentUser.next(null);
  }

  // Methods for membershipLogin model
  public get membershipValue(): MembershipLogin {
    return this.membership.value;
  }

  changeMembershipLogin(membershipLogin: MembershipLogin) {
    this.membership.next(membershipLogin);
    // this.localStorageService.setItem('ml', membershipLogin);
  }

  // api calls
  sendLoginRequest(payload: object) {
    return this.apiService.post<any>(this.sendUrl, payload);
  }

  verifyLoginRequest(payload: object, cacheResult = true): Observable<User> {
    return this.apiService.post<User>(this.verifyUrl, payload).pipe(
      map((user: User) => {
        if (cacheResult) {
          this.localStorageService.setItem('token', {
            token: user.token,
            user: user.user
          });
          this.currentUser.next(user);
          this.userMemberships.next(user.memberships);
          return user;
        }
      })
    );
  }

  verifyBookSessionRequest(
    payload: object,
    cacheResult = true
  ): Observable<User> {
    return this.apiService.post<User>(this.verifyUrl, payload).pipe(
      map((user: User) => {
        if (cacheResult) {
          this.currentUser.next(user);
          // this.userMemberships.next(user.memberships);
          return user;
        }
      })
    );
  }

  checkMembership(
    membershipId: string,
    communityId: string
  ): Observable<Membership> {
    return this.apiService
      .get<Membership>(
        `communities/${communityId}/memberships/${membershipId}/`
      )
      .pipe(
        tap(el => {
          this.changeMembershipLogin({
            membershipId,
            communityId
          });
        })
      );
  }

  getMemberships(id: number): Observable<Membership[]> {
    return this.apiService.get<Membership[]>(`users/${id}/memberships/`);
  }
}
