import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Event, BaseEventUrlMap } from '@shared/interfaces/event';
import { ApiService } from '@core/api/api.service';
import { Utils } from '@pay/pay/utils';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  event: Event;

  private eventInfo = new BehaviorSubject<Event>(null);
  eventInfo$ = this.eventInfo.asObservable();

  constructor(private apiService: ApiService) {}

  fetchDetail(
    communityId: string,
    eventId: string,
    subFeature: string
  ): Observable<Event> {
    return this.apiService
      .get<Event>(
        `communities/${communityId}/${BaseEventUrlMap[subFeature]}/${eventId}`
      )
      .pipe(
        map(eventDetail => {
          const mappedPaymentMethods = Utils.paymentMethodsAdapter(
            eventDetail.payment_methods
          );

          eventDetail.mappedPaymentMethods = mappedPaymentMethods;

          return eventDetail;
        })
      );
  }

  fetchList(communityId: string, subFeature: string): Observable<Event[]> {
    return this.apiService.get<Event[]>(
      `communities/${communityId}/${BaseEventUrlMap[subFeature]}`
    );
  }

  changeEventInfo(eventInfo: Event) {
    this.eventInfo.next(eventInfo);
  }

  currentEventValue(): Event {
    return this.eventInfo.value;
  }
}
