import { Component, OnInit, OnDestroy } from '@angular/core';
import { XpayService } from 'src/app/features/community/shared/xpay.service';
import { LayoutService } from 'src/app/layout/layout.service';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss']
})
export class Page404Component implements OnInit, OnDestroy {
  constructor(private layoutService: LayoutService) {}

  ngOnInit() {
    this.layoutService.hideCommunityHeader();
  }

  ngOnDestroy() {
    this.layoutService.showCommunityHeader();
  }
}
