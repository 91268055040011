import { FormlyFieldConfig } from '@ngx-formly/core';

import {
  CustomField,
  CustomFieldTypeMap,
  FieldType,
  CustomFieldPayload
} from '@shared/interfaces/custom-field';
import {
  PayStep,
  PayStepAction,
  PayStepName
} from '@shared/interfaces/pay-step';
import { PaymentMethodType } from '@shared/interfaces/payment-method';

export class Utils {
  static mobileVerification = true;

  static customFieldsAdapter(customFields: CustomField[]): FormlyFieldConfig[] {
    const newFields: FormlyFieldConfig[] = [];
    let fieldGroup: FormlyFieldConfig[] = [];

    customFields.forEach((el, index) => {
      let options = null;
      if (el.field_choices) {
        options = [];
        const choices = el.field_choices.split(',');
        for (let choice of choices) {
          choice = choice.trim();
          options.push({ value: choice, label: choice });
        }
      }
      const newField: FormlyFieldConfig = {
        id: el.id.toString(),
        key: el.field_label.toString(),
        // name: el.id.toString(),

        className: 'form-group col-12 col-sm-6',
        type: CustomFieldTypeMap[el.field_type].mainType,
        templateOptions: {
          label: el.field_label,
          type: CustomFieldTypeMap[el.field_type].subType,
          required: el.required,
          placeholder: `Enter ${el.field_label} here`,
          description: el.help_text,
          options
        }
      };

      // set default values
      switch (el.field_type) {
        case FieldType.CHECKBOX:
          newField.defaultValue = false;
          break;
        case FieldType.TEXT:
        case FieldType.NUMBER:
          break;

        default:
          break;
      }

      // in case of optional
      if (!el.required) {
        switch (el.field_type) {
          case FieldType.CHECKBOX:
          case FieldType.TEXT:
          case FieldType.NUMBER:
            newField.defaultValue = '';
            break;

          default:
            break;
        }
      }

      fieldGroup.push(newField);

      if (fieldGroup.length === 2 || index === customFields.length - 1) {
        newFields.push({
          fieldGroupClassName: 'row',
          fieldGroup: [...fieldGroup]
        });

        fieldGroup = [];
      }
    });

    return newFields;
  }

  static initPayWizardSteps(
    hasCustomFields: boolean,
    hasShipping: boolean,
    hasMobileVerification: boolean,
    hasInstallment: boolean
  ): PayStep[] {
    const initialPayWizardSteps: PayStep[] = [
      {
        name: PayStepName.BILLING,
        allowed: true,
        action: PayStepAction.NEXT,
        step: 1
      },
      {
        name: PayStepName.VERIFIED,
        allowed: true,
        action: PayStepAction.NEXT,
        step: 2
      },
      {
        name: PayStepName.CUSTOM,
        allowed: true,
        action: PayStepAction.NEXT,
        step: 3
      },
      {
        name: PayStepName.SHIPPING,
        allowed: true,
        action: PayStepAction.NEXT,
        step: 4
      },
      {
        name: PayStepName.INSTALLMENT,
        allowed: true,
        action: PayStepAction.NEXT,
        step: 5
      },
      {
        name: PayStepName.FINAL,
        allowed: true,
        action: null,
        step: 6
      }
    ];

    if (!hasCustomFields) {
      initialPayWizardSteps[2].allowed = false;
    }
    if (!hasMobileVerification) {
      initialPayWizardSteps[1].allowed = false;
      this.mobileVerification = false;
    }
    if (!hasShipping) {
      initialPayWizardSteps[3].allowed = false;
    }
    if (!hasInstallment) {
      initialPayWizardSteps[4].allowed = false;
    }

    for (let i = initialPayWizardSteps.length - 2; i >= 0; i--) {
      if (!initialPayWizardSteps[i].allowed) {
        initialPayWizardSteps[i].action = null;
      } else {
        initialPayWizardSteps[i].action = PayStepAction.SUBMIT;
        break;
      }
    }

    let stepCounter = 1;
    initialPayWizardSteps.forEach(el => {
      if (el.allowed) {
        el.step = stepCounter;
        stepCounter++;
      }
    });

    return initialPayWizardSteps;
  }

  static createCustomFieldsIdsMap(customFields: CustomField[]) {
    const customFieldsIdsMap = {};

    customFields.forEach(el => {
      customFieldsIdsMap[el.field_label] = el.id;
    });

    return customFieldsIdsMap;
  }
  static getMobileVerificationStatus(): boolean {
    return this.mobileVerification;
  }
  static createCustomFieldsPayload(
    customFields: CustomField[],
    customFieldsValues: { [key: string]: any }
  ): CustomFieldPayload[] {
    const customFieldsIdsMap = this.createCustomFieldsIdsMap(customFields);

    const customFieldsPayload = [];

    for (const [key, value] of Object.entries(customFieldsValues)) {
      const customFieldPayloadElement = {
        field_id: customFieldsIdsMap[key],
        field_label: key,
        field_value: value
      };

      customFieldsPayload.push(customFieldPayloadElement);
    }

    return customFieldsPayload;
  }

  static paymentMethodsAdapter(paymentMethods: string[]): PaymentMethodType[] {
    const newPaymentMethods: PaymentMethodType[] = paymentMethods.map(el => {
      switch (el) {
        case 'Kiosk'.toUpperCase():
          return PaymentMethodType.KIOSK;

        case 'Card'.toUpperCase():
          return PaymentMethodType.NEWCARD;

        case 'Cash'.toUpperCase():
          return PaymentMethodType.CASHONDELIVERY;

        case 'meeza/digital'.toUpperCase():
          return PaymentMethodType.UPG;

        case 'Installment'.toUpperCase():
          return PaymentMethodType.INSTALLMENT;

        case 'Fawry'.toUpperCase():
          return PaymentMethodType.FAWRY;
        case 'valu'.toUpperCase():
          return PaymentMethodType.VALU;
        default:
          break;
      }
    });
    return newPaymentMethods;
  }

  static roundNumber(num: number, precision: number = 2) {
    // source = https://stackoverflow.com/a/35715420
    let pair = (num + 'e').split('e');
    const value = Math.round(Number(pair[0] + 'e' + (+pair[1] + precision)));
    pair = (value + 'e').split('e');
    return +(pair[0] + 'e' + (+pair[1] - precision));
  }
}
