import { Component, OnInit, HostBinding } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';

import { TranslocoService } from '@ngneat/transloco';

import { AuthService } from 'src/app/core/auth/auth.service';
import { User } from 'src/app/shared/interfaces/user';
import { Observable } from 'rxjs';
import { XpayService } from 'src/app/features/community/shared/xpay.service';
import { Community } from 'src/app/shared/interfaces/community';
import { LayoutService } from '../layout.service';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { LocalStorageService } from '@core/web-storage/local-storage.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @HostBinding('style.display') headerDisplay = 'block';

  lang: string;

  communityLogo: string;
  userInfo$: Observable<User>;
  communityData$: Observable<Community>;
  communityHeaderVisability$: Observable<boolean>;

  isUser: boolean;
  isCommunity: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private translateService: TranslocoService,
    private communityService: XpayService,
    private layoutService: LayoutService,
    private localStorage: LocalStorageService
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        // for hiding header only when navigate to event embed
        // ex: ["", "embed", "event", ":communityid", ":eventid"]
        // ex: ["", "i", "community", "7", "direct-order", "1"]
        const routerURLSplit = router.url.split('/');
        if (routerURLSplit.length >= 2 && routerURLSplit[1] === 'embed') {
          this.headerDisplay = 'none';
        } else {
          this.headerDisplay = 'block';
        }
      }
    });
  }

  ngOnInit() {
    this.communityHeaderVisability$ = this.layoutService.communityHeaderVisability$;
    this.userInfo$ = this.authService.currentUser$;
    this.communityData$ = this.communityService.communityInfo$;

    this.lang = this.translateService.getActiveLang();
    this.communityData$ = this.communityService.communityInfo$.pipe(
      filter(community => community !== null),
      distinctUntilChanged(),
      map((community: Community) => {
        if (community.login_process === 'NOAUTH') {
          this.localStorage.setItem('LoginType', 'NOAUTH');
          // this.router.navigate(['i', 'community', community.id]);
        } else {
          this.localStorage.setItem('LoginType', community.login_process);
        }
        return community;
      })
    );
  }

  changeLang(lang: string) {
    if (lang === this.lang) {
      return;
    }
    this.lang = lang;
    this.translateService.setActiveLang(lang);
  }
}
