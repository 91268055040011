import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private loading = new BehaviorSubject<boolean>(null);
  loading$ = this.loading.asObservable();

  constructor() {}

  startLoading() {
    this.loading.next(true);
  }
  stopLoading() {
    this.loading.next(false);
  }
  get loadingStatus(): boolean {
    return this.loading.value;
  }
}
