import { PaymentMethodType } from './payment-method';
import { Currency } from './currency';

export interface MappedCommunity {
  mappedModules?: CommunityModuleValue[];
  mappedPaymentMethods?: PaymentMethodType[];
}

export interface Community extends MappedCommunity {
  id: string;
  name: string;
  logo: string;
  app_modules_to_see: string[];
  payment_methods: string[];
  address: string;
  cash_collection_fees_amount?: number;
  cash_collection_fees_amount_currency?: Currency;
  login_process: LoginType;
  payment_processor: PaymentProcessors;
  bm_merchant_id?: string;
  nbe_merchant_id?: string;
  show_calculation_may_differ_warning?: boolean;
  allow_promo_code?: boolean;
  supports_installments?: boolean;
}

export enum PaymentProcessors {
  BANQUE_MISR = 'BM',
  BANQUE_AHLY = 'NBE',
  ACCEPT_PAYMENTS = 'AP'
}

export type LoginType = 'MEMBER_ID' | 'OTP_MEMBER_ID' | 'OTP' | 'NOAUTH';

export type CommunityModule =
  | 'Bill'
  | 'Event'
  | 'Training'
  | 'Course'
  | 'Sport';

export enum CommunityModules {
  BILL = 'Bill',
  EVENT = 'Event',
  TRAINING = 'Training',
  // ACADEMY = 'Academy',
  SERVICE = 'Service',
  PRODUCT = 'Product',
  SUBSCRIPTION = 'Subscription',
  SPORT = 'Sport',
  COURSE = 'Course'
}

export interface CommunityModuleValue {
  routerLink: string;
  title: string;
  logo?: string;
}

export type CommunityModuleMap = {
  [x in CommunityModules]: CommunityModuleValue;
};

export const featuresMap = new Map([
  ['bills', 'Bill'],
  ['subscriptions', 'Subscription'],
  ['events', 'Event'],
  ['trainings', 'Training'],
  ['sports', 'Academy'],
  ['team', 'Academy'],
  // ['academy', 'Academy'],
  ['services', 'Service'],
  ['products', 'Product'],
  ['sport', 'Sport'],
  ['course', 'Course']
]);
