import { Pipe, PipeTransform, ChangeDetectorRef, Inject } from '@angular/core';

import {
  TranslocoCurrencyPipe,
  TranslocoLocaleService,
  LOCALE_CONFIG,
  LocaleConfig,
  Currency
} from '@ngneat/transloco-locale';
import { TotalBreakdownService } from '@community/shared/total-breakdown.service';
import { Currency as xpCurrency } from '@shared/interfaces/currency';

import { selectMinusSign, selectDigits, selectCurrency } from './helpers';

@Pipe({
  name: 'xpCurrency',
  pure: false
})
export class XpCurrencyPipe extends TranslocoCurrencyPipe
  implements PipeTransform {
  constructor(
    private breakdownService: TotalBreakdownService,
    protected _translocoLocaleService: TranslocoLocaleService,
    protected _cdr: ChangeDetectorRef,
    @Inject(LOCALE_CONFIG) _localeConfig: LocaleConfig
  ) {
    super(_translocoLocaleService, _cdr, _localeConfig);
  }

  transform(
    value: number | string,
    currencyCode: Currency = this.breakdownService.currentPaymentCurrency
  ): string {
    const locale = this._translocoLocaleService.getLocale();
    const currencyFormat = super.transform(value, 'symbol', {}, currencyCode);

    if (locale === 'en-US' && currencyCode === xpCurrency.EGP) {
      const minusSign = selectMinusSign(currencyFormat);
      const digits = selectDigits(currencyFormat);
      const currency = selectCurrency(currencyFormat);

      return `${minusSign}${digits}\xa0${currency}`;
    }

    return currencyFormat;
  }
}
