import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  communityId: string;
  eventId: string;

  appContext: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private angulartics: Angulartics2GoogleGlobalSiteTag
  ) {
    if (environment.ENV_NAME === 'production') {
      angulartics.startTracking();
    }
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe(queryParams => {
      this.communityId = queryParams.get('community_id');
      this.eventId = queryParams.get('event_template');

      if (this.communityId) {
        this.router.navigate(['i', 'community', this.communityId]);
      }

      // if (this.eventId) {
      //   // this.eventService.redirectToEventUrl(parseInt(this.eventId, 10));
      // }
    });
  }
}
