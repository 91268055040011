import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { CountryISO } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-intl-input',
  templateUrl: './intl-input.component.html',
  styleUrls: ['./intl-input.component.scss']
})
export class IntlInputComponent implements AfterViewInit {
  @Input() control: FormControl;
  @Input() isFormSubmitted: boolean;
  @Input() placeholder: string = null;
  @Output() keyEnter = new EventEmitter<{}>();

  CountryISO = CountryISO;

  constructor(private elRef: ElementRef) {}

  ngAfterViewInit() {
    if (this.placeholder) {
      this.setPlaceholder(this.placeholder);
    }
  }

  setPlaceholder(text: string) {
    const intlPhoneInput = this.elRef.nativeElement.querySelector(
      '#phone'
    ) as HTMLInputElement;
    intlPhoneInput.placeholder = text;
  }
}
