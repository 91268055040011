import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-counter',
  templateUrl: './input-counter.component.html',
  styleUrls: ['./input-counter.component.scss']
})
export class InputCounterComponent implements OnInit {
  @Output() counterEmitter = new EventEmitter<number>();
  @Input() counter = 0;
  @Input() upperLimit = Infinity;
  @Input() lowerLimit = 0;

  constructor() {}

  ngOnInit() {}

  incrementCounter() {
    if (this.applyUpperLimit()) {
      return;
    }

    this.counter += 1;

    this.emitCounter();
  }

  decrementCounter() {
    if (this.applyLowerLimit()) {
      return;
    }

    this.counter -= 1;

    this.emitCounter();
  }

  applyUpperLimit() {
    if (this.counter === this.upperLimit) {
      return true;
    }
  }

  applyLowerLimit() {
    if (this.counter === this.lowerLimit) {
      return true;
    }
  }

  emitCounter() {
    this.counterEmitter.emit(this.counter);
  }
}
