import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ToastrModule } from 'ngx-toastr';
import { AvatarModule } from 'ngx-avatar';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  FontAwesomeModule,
  FaIconLibrary
} from '@fortawesome/angular-fontawesome';
import {
  fas,
  faFutbol,
  faSignOutAlt,
  faBookmark,
  faTimesCircle,
  faAngleDown,
  faCaretDown,
  faPlus,
  faMinus,
  faCircle,
  faCheck,
  faBasketballBall,
  faExclamationCircle,
  faUserFriends,
  faArrowLeft,
  faEdit
} from '@fortawesome/free-solid-svg-icons';
import {
  faBookmark as farBookmark,
  faClock as farClock,
  faCommentAlt as farCommentAlt
} from '@fortawesome/free-regular-svg-icons';

import { SharedBootstrapModule } from './shared-bootstrap/shared-bootstrap.module';

import { Page404Component } from './components/page404/page404.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { RtlDirective } from './directives/rtl.directive';
import { InputCounterComponent } from './components/input-counter/input-counter.component';
import { InputCounterTeamComponent } from './components/input-counter-team/input-counter-team.component';
import { ReadMoreComponent } from './components/read-more/read-more.component';
import { NgxSubscribeDirective } from './directives/ngx-subscribe.directive';
import { MembershipFormComponent } from './components/form-components/membership-form/membership-form.component';
import { OtpLoginComponent } from './components/otp-login/otp-login.component';
import { TotalBreakdownComponent } from './components/total-breakdown/total-breakdown.component';
import { IntlInputComponent } from './components/intl-input/intl-input.component';
import { VariableAmountWidgetComponent } from '@shared/components/variable-amount-widget/variable-amount-widget.component';
import { EventWidgetComponent } from './components/event-widget/event-widget.component';
import { XpCurrencyPipe } from './pipes/xp-currency/xp-currency.pipe';
import { ByPassSecurityPipe } from './pipes/bypass-security/bypass-security.pipe';
import { CheckBoxComponent } from './components/check-box/check-box.component';

const components = [
  Page404Component,
  SpinnerComponent,
  InputCounterComponent,
  InputCounterTeamComponent,
  MembershipFormComponent,
  OtpLoginComponent,
  TotalBreakdownComponent,
  IntlInputComponent,
  VariableAmountWidgetComponent,
  EventWidgetComponent,
  ReadMoreComponent,
  CheckBoxComponent
];

const directives = [RtlDirective, NgxSubscribeDirective];

const pipes = [XpCurrencyPipe, ByPassSecurityPipe];

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  RouterModule,

  SharedBootstrapModule,

  TranslocoModule,
  TranslocoLocaleModule,
  NgOtpInputModule,
  NgxIntlTelInputModule,
  ToastrModule,
  FontAwesomeModule,
  AvatarModule,
  VirtualScrollerModule,
  NgSelectModule
];

@NgModule({
  declarations: [...components, ...directives, ...pipes],
  imports: [...modules],
  exports: [...modules, ...components, ...directives, ...pipes]
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    // library.addIconPacks(fas);
    library.addIcons(
      faFutbol,
      faSignOutAlt,
      faBookmark,
      farBookmark,
      faTimesCircle,
      faAngleDown,
      faCaretDown,
      faPlus,
      faMinus,
      faCircle,
      faCheck,
      faBasketballBall,
      faExclamationCircle,
      faUserFriends,
      faArrowLeft,
      farClock,
      farCommentAlt,
      faEdit
    );
  }
}
