import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';

if (environment.production) {
  enableProdMode();
}

// Hide console log in production and staging envs
if (environment.production || environment.ENV_NAME === 'staging') {
  enableProdMode();
  if (window) {
    window.console.log = () => {};
  }

  Sentry.init({
    dsn: environment.SENTRY_DSN,
    environment: environment.ENV_NAME,
    ignoreErrors: [
      'Non-Error exception captured',
      'Unexpected end of JSON input'
    ]
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
