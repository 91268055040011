import { Component, OnInit, forwardRef, OnDestroy, Input } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  NG_VALUE_ACCESSOR,
  FormControl,
  ControlValueAccessor,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS
} from '@angular/forms';
import { Subscription, of } from 'rxjs';
import { ApiService } from 'src/app/core/api/api.service';
import { XpayService } from 'src/app/features/community/shared/xpay.service';
import { Membership } from 'src/app/shared/interfaces/user';
import { map, catchError } from 'rxjs/operators';

export interface MembershipFormValues {
  membership_id: string;
}

@Component({
  selector: 'app-membership-form',
  templateUrl: './membership-form.component.html',
  styleUrls: ['./membership-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MembershipFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MembershipFormComponent),
      multi: true
    }
  ]
})
export class MembershipFormComponent
  implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() disabledFlag: boolean;
  @Input() inputBig: boolean;

  form: FormGroup;
  subscriptions: Subscription[] = [];
  communityId: string;

  get value(): MembershipFormValues {
    return this.form.value;
  }

  set value(value: MembershipFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get membershipControl() {
    return this.form.controls.membership_id;
  }

  constructor(
    private formBuilder: FormBuilder,
    private xpayService: XpayService
  ) {}

  ngOnInit() {
    this.communityId = this.xpayService.communityId;

    this.initMembershipForm(this.disabledFlag);

    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  initMembershipForm(disabled: boolean) {
    this.form = this.formBuilder.group({
      membership_id: [
        '',
        Validators.required
        // this.validateMembershipId.bind(this)
      ]
    });

    if (disabled) {
      this.membershipControl.disable();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    console.log('validate function sent: ', this.form.valid);
    return this.form.valid ? null : { valid: false };
  }

  // onInputKeyPress(event: KeyboardEvent): void {
  //   const allowedChars = /[0-9]/;
  //   if (!allowedChars.test(event.key)) {
  //     event.preventDefault();
  //   }
  // }

  // validateMembershipId(control: AbstractControl) {
  // validate(control: FormControl) {
  //   if (control.value) {
  //     return this.apiService
  //       .get<Membership>(
  //         `communities/${this.communityId}/memberships/${control.value.membership_id}/`
  //       )
  //       .pipe(
  //         map(res => (res ? null : { notFound: true })),
  //         catchError(() => of({ notFound: true }))
  //       );
  //   } else {
  //     return of({ notFound: true });
  //   }
  // }
}
