const MINUS_REGEX = /-/;
const DIGITS_REGEX = /[0-9,.]+/;
const CURRENCY_REGEX = /[^0-9,.-\s]+/;

function baseSelect(currencyFormat: string, re: RegExp): string {
  return currencyFormat.match(re) ? currencyFormat.match(re)[0] : '';
}

export function selectMinusSign(currencyFormat: string): string {
  return baseSelect(currencyFormat, MINUS_REGEX);
}

export function selectDigits(currencyFormat: string): string {
  return baseSelect(currencyFormat, DIGITS_REGEX);
}

export function selectCurrency(currencyFormat: string): string {
  return baseSelect(currencyFormat, CURRENCY_REGEX);
}
