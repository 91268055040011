export interface ShippingInfo {
  country: string;
  state: string;
  city: string;
  postal_code?: string;
  street: string;
  building: string;
  apartment: string;
  floor: string;
  address_extra_info: string;
}

export const ShippingKeys = [
  'country',
  'state',
  'city',
  'street',
  'building',
  'apartment',
  'floor',
  'address_extra_info'
];

// DEO wants to change the labels and i quote "state must be renamed to city, city must be renamed to District"
export enum ShippingKeysNames {
  country = 'country',
  state = 'city',
  city = 'district',
  street = 'street',
  building = 'building',
  apartment = 'apartment',
  floor = 'floor',
  address_extra_info = 'address_extra_info'
}
