import { NgModule } from '@angular/core';

import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { SharedModule } from '../shared/shared.module';

import { HeaderComponent } from './header/header.component';
// import { LogoutComponent } from './header/logout/logout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AvatarMenuComponent } from './header/logout/avatar-menu.component';
import { CommunityHeaderComponent } from './header/community-header/community-header.component';
import { WLayoutComponent } from './w-layout/w-layout.component';
import { ILayoutComponent } from './i-layout/i-layout.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [
    // LogoutComponent,
    HeaderComponent,
    AvatarMenuComponent,
    CommunityHeaderComponent,
    FooterComponent,
    SidebarComponent,
    WLayoutComponent,
    ILayoutComponent
  ],
  imports: [SharedModule, RouterModule],
  exports: [
    // LogoutComponent,
    HeaderComponent,
    AvatarMenuComponent,
    CommunityHeaderComponent,
    FooterComponent,
    SidebarComponent,
    WLayoutComponent,
    ILayoutComponent,

    SharedModule
  ],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'layout' }]
})
export class LayoutModule {}
