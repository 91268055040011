import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-i-layout',
  templateUrl: './i-layout.component.html',
  styleUrls: ['./i-layout.component.scss']
})
export class ILayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
