import {
  Component,
  Input,
  ElementRef,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss']
})
export class ReadMoreComponent implements OnChanges {
  @Input() text: string;
  @Input() maxLength = 100;
  @Input() className: string;
  @Input() readonly = false;

  @Output() clickListener = new EventEmitter<any>();

  currentText: string;
  hideToggle = true;

  public isCollapsed = true;

  constructor(private elementRef: ElementRef) {}

  onClickButton(event) {
    this.clickListener.emit(event);
  }

  toggleView() {
    if (!this.readonly) {
      this.isCollapsed = !this.isCollapsed;
      this.determineView();
    }
  }
  determineView() {
    // this.text = (this.extractContent(this.text))
    this.text = this.text.replace('Powered by Froala Editor', '');
    if (!this.text || this.text.length <= this.maxLength) {
      this.currentText = this.text;
      this.isCollapsed = false;
      this.hideToggle = true;
      return;
    }
    this.hideToggle = false;
    if (this.isCollapsed === true) {
      this.currentText = this.text.substring(0, this.maxLength) + '...';
    } else if (this.isCollapsed === false) {
      this.currentText = this.text;
    }
  }
  ngOnChanges() {
    this.determineView();
  }

  extractContent(s) {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  getClassName() {
    const classes = {
      'profile-p': this.className === 'profile-p'
    };

    return classes;
  }
}
