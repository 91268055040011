import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Community } from '@shared/interfaces/community';
import { CommunityErrors } from '@shared/interfaces/communityError';
import { Membership } from '@shared/interfaces/user';
import { AuthService } from '@core/auth/auth.service';
import { LocalStorageService } from '@core/web-storage/local-storage.service';
import { ApiService } from '@core/api/api.service';
import { PaymentMethodType } from '@shared/interfaces/payment-method';

import { Utils } from '@pay/pay/utils';

@Injectable({
  providedIn: 'root'
})
export class XpayService {
  private id: string;
  private communitydata: Community = null;
  private communityUrl = 'communities/preferences/?community_id=';
  // private membershipUrl = 'communities/preferences/?community_id=';

  // Model for current active community
  // community component is responsible for updating the model
  private communityInfo = new BehaviorSubject<Community>(null);
  communityInfo$ = this.communityInfo.asObservable();

  private communityMemberError = new BehaviorSubject<CommunityErrors>(null);
  communityMemberError$ = this.communityMemberError.asObservable();

  // Model for user memberships related to active community
  // community component is responsible for updating the model
  private memberships = new BehaviorSubject<Membership[]>(null);
  memberships$ = this.memberships.asObservable();

  constructor(
    private api: ApiService,
    private authService: AuthService,
    private localStorateService: LocalStorageService
  ) {}

  // Methods related to memberships model
  changeMemberships() {
    /* updates the memberships model with an array of memberships
    that belong to the user in the active community */
    if (this.authService.currentUserMemberships) {
      console.log('user memberships:', this.authService.currentUserMemberships);
      this.filterMemberships(this.authService.currentUserMemberships);
    } else if (this.authService.currentUserValue) {
      this.authService
        .getMemberships(this.authService.currentUserValue.user.id)
        .subscribe(el => {
          this.filterMemberships(el);
        });
    }
  }

  filterMemberships(memberships: Membership[]) {
    const communityMemberships: Membership[] = [];
    const userMemberships = memberships;
    // filter memberships
    for (const membership of userMemberships) {
      if (membership.community.id === this.communityId) {
        communityMemberships.push(membership);
      }
    }
    this.memberships.next(communityMemberships);
    console.log(
      'community service:',
      ' community memberships:',
      communityMemberships
    );
  }

  pushMembership(membership: Membership) {
    const communityMemberships: Membership[] = [];
    communityMemberships.push(membership);
    this.memberships.next(communityMemberships);
  }

  clearMemberships(): void {
    this.memberships.next(null);
  }

  get currentCommunityMemberships() {
    /* return selected membership ido */
    return this.memberships.value;
  }

  getMembership(
    membershipId: string,
    communityId: string
  ): Observable<Membership> {
    return this.api.get<Membership>(
      `communities/${communityId}/memberships/${membershipId}`
    );
  }

  // Methods for community model
  getCommunityPrefrences(id: string): Observable<Community> {
    return this.api.get<Community>(`${this.communityUrl}${id}`).pipe(
      map(community => {
        const mappedPaymentMethods = Utils.paymentMethodsAdapter(
          community.payment_methods
        );

        community.mappedPaymentMethods = mappedPaymentMethods;

        return community;
      })
    );
  }

  changeCommunity(community: Community) {
    this.communityInfo.next(community);
  }

  clearCommunity() {
    this.communityInfo.next(null);

    this.localStorateService.removeItem('cid');

    this.id = null;
  }

  public get community(): Community {
    return this.communityInfo.value;
  }

  get communityId(): string {
    return this.id;
  }

  set communityId(id: string) {
    // set community id in local storage
    this.localStorateService.setItem('cid', id);

    this.id = id;
  }

  get communityData(): Community {
    return this.communitydata;
  }

  set communityData(communityData: Community) {
    this.communitydata = communityData;
  }

  emitCommunityMemberError(CommunityError: CommunityErrors) {
    this.communityMemberError.next(CommunityError);
  }
}
