import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { TranslocoService } from '@ngneat/transloco';

import { Subject } from 'rxjs';

import { AuthService } from 'src/app/core/auth/auth.service';
import { User } from 'src/app/shared/interfaces/user';
import { takeUntil } from 'rxjs/operators';
import { XpayService } from 'src/app/features/community/shared/xpay.service';

@Component({
  selector: 'app-avatar-menu',
  templateUrl: './avatar-menu.component.html',
  styleUrls: ['./avatar-menu.component.scss']
})
export class AvatarMenuComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();

  currentUserValue: User;
  currentUserName: string;

  currentLang: string;

  constructor(
    private authService: AuthService,
    private communityService: XpayService,
    private router: Router,
    private translateService: TranslocoService
  ) {}

  ngOnInit() {
    this.currentUserValue = this.authService.currentUserValue;
    this.currentUserName = this.currentUserValue.user.name
      .split(' ', 2)
      .join(' ');

    this.translateService.langChanges$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(lang => (this.currentLang = lang));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  logout() {
    this.authService.logout();
    this.communityService.clearCommunity();

    // navigate to login page
    this.router.navigate(['i', 'auth']);
  }
}
