import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-counter-team',
  templateUrl: './input-counter-team.component.html',
  styleUrls: ['./input-counter-team.component.css']
})
export class InputCounterTeamComponent implements OnInit {
  @Output() counterEmitter = new EventEmitter<number>();
  @Input() counter = 0;
  @Input() upperLimit = Infinity;
  @Input() lowerLimit = 0;

  constructor() {}

  ngOnInit() {}

  incrementCounter() {
    if (this.applyUpperLimit()) {
      return;
    }
    if (this.counter === 1 || this.counter === 0) {
      this.counter += 1;
      this.emitCounter();
    } else {
      this.counter += 2;
      this.emitCounter();
    }
  }

  decrementCounter() {
    if (this.applyLowerLimit()) {
      return;
    }

    if (this.counter === 2 || this.counter === 1) {
      this.counter -= 1;
      this.emitCounter();
    } else {
      this.counter -= 2;
      this.emitCounter();
    }
  }

  applyUpperLimit() {
    if (this.counter === this.upperLimit) {
      return true;
    }
  }

  applyLowerLimit() {
    if (this.counter === this.lowerLimit) {
      return true;
    }
  }

  emitCounter() {
    this.counterEmitter.emit(this.counter);
  }
}
