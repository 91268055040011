import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Inject,
  TemplateRef
} from '@angular/core';
import { Event } from 'src/app/shared/interfaces/event';
import { PayService } from '@pay/pay/pay.service';
import { PayType } from '@shared/interfaces/payType';
import { AmountBreakdown } from '@shared/interfaces/amount-breakdown';
import { TotalBreakdownService } from 'src/app/features/community/shared/total-breakdown.service';
import { EventService } from '@community/offerings/event/event.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EventCartInfo } from '@shared/interfaces/cartInfo';
import { XpayService } from '@community/shared/xpay.service';
import { BillingInfo } from '@shared/interfaces/billingInfo';
import { PaymentModuleService } from '@community/shared/payment-module.service';
import { MODULE_SCOPE } from '@shared/module-scope';
import { ModuleScope } from '@shared/interfaces/module-scope';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '@core/auth/auth.service';

@Component({
  selector: 'app-event-widget',
  templateUrl: './event-widget.component.html',
  styleUrls: ['./event-widget.component.scss']
})
export class EventWidgetComponent implements OnInit {
  event: Event = null;
  communityId: string = null;
  userSelectedTickets: number[] = [];
  totalBreakdown: AmountBreakdown;
  ticketsExpired: boolean;
  currentState:
    | PayType.EVENT
    | PayType.SERVICE
    | PayType.TRAINING
    | PayType.PRODUCT;
  @Output() payEvent = new EventEmitter<number[]>();
  checkboxFlag = false;
  conditionsFlag = false;
  safeDescription: SafeHtml;
  modalRef: BsModalRef;
  queryParams: { [key: string]: any };
  selectedTicketsInfo: { id: number; ticket_price: number; count: number; ticket_total_amount: number }[] = [];
  constructor(
    private route: ActivatedRoute,
    private payService: PayService,
    private eventService: EventService,
    private breakdownService: TotalBreakdownService,
    private xpayService: XpayService,
    private router: Router,
    private paymentModuleService: PaymentModuleService,
    private sanitizer: DomSanitizer,
    private modalService: BsModalService,
    @Inject(MODULE_SCOPE) private scope: ModuleScope
  ) {}

  ngOnInit() {
    this.event = this.eventService.currentEventValue();
    this.currentState = this.route.snapshot.data.state;
    this.communityId = this.xpayService.communityId;

    console.log(this.currentState);

    // pass billing info from query params (event-embed case)
    this.route.queryParams.subscribe((el: BillingInfo) => {
      this.payService.changeBillingInfo(el);
      this.queryParams = el;
    });

    // init total breakdown obj
    if (this.event.tickets.length > 0) {
      this.totalBreakdown = this.breakdownService.initTotalBreakdown(
        this.event,
        this.currentState
      );
    }

    this.event.tickets.forEach(el => this.userSelectedTickets.push(0));

    this.checkTicketsExpiration();

    // set payment module data
    this.paymentModuleService.changePaymentModule({ name: this.event.name });

    // get terms and conditions if exists
    if (
      this.event.terms_and_condition !== 'N/A' &&
      this.event.terms_and_condition.length > 0
    ) {
      this.conditionsFlag = true;
      this.safeDescription = this.sanitizer.bypassSecurityTrustHtml(
        this.event.terms_and_condition
      );
      console.log(this.safeDescription);
    }
    if (this.event.allow_recurring_payments) {
      this.payService.changeHasRecurringPaymentStatus(true);
    } else {
      this.payService.changeHasRecurringPaymentStatus(false);
    }
  }

  calculateTicketsTotalChange(product: Event, ticketsArr: number[]) {
    this.totalBreakdown = this.breakdownService.initTotalBreakdown(
      product,
      this.currentState
    );

    this.selectedTicketsInfo = product.tickets.map((ticket, index) => {
      const count = ticketsArr[index] || 0;
      const ticketAmount = ticket.total.sub_total_amount || 0; // Ticket price (single amount)
      const total = count * ticketAmount;
      return {
        id: ticket.id,
        ticket_price: ticketAmount,
        count: count,
        ticket_total_amount: total
      };
    });

    // Initialize sub_total_amount
    let subTotalAmount = 0;
    let vatTotalAmount = 0;

    this.totalBreakdown.installment_fees = [];

    product.tickets.forEach((el, index) => {
      // Sub total
      if (el.limit_per_user && el.limit_per_user > 0) {
        el.limit = Math.min(el.limit_per_user, el.available_quantity);
      } else {
        el.limit = el.available_quantity;
      }
      // Accumulate sub_total_amount for each ticket type
      subTotalAmount += el.total.sub_total_amount * ticketsArr[index];
      this.totalBreakdown.sub_total_amount = subTotalAmount;
      const xpayPercentage = el.total.xpay_percentage;
      const account_type = el.total.account_type;
      const adminPercentage = el.total.adminstrative_percentage;
      const isCalcVat = el.total.is_calc_vat;
      const fixedAmount = el.total.fixed_amount;
      const minAmount = el.total.min_amount;
      const maxAmount = el.total.max_amount;
      const discountPercentage = el.total.discount_amount;
      vatTotalAmount += el.total.vat_amount * ticketsArr[index];
      const adminFeesAmount = this.calculateAdminFees(
        subTotalAmount,
        adminPercentage,
        account_type
      );
      const serviceFeesAmount = this.calculateServiceFees(
        subTotalAmount,
        xpayPercentage,
        isCalcVat,
        fixedAmount,
        account_type,
        vatTotalAmount,
        adminFeesAmount,
        minAmount,
        maxAmount,
        discountPercentage
      );
      this.totalBreakdown.vat_amount = vatTotalAmount;
      this.totalBreakdown.service_fees_amount = serviceFeesAmount;
      this.totalBreakdown.administrative_fees_amount = adminFeesAmount;

      if (el.total.installment_fees) {
        el.total.installment_fees.forEach(period => {
          const currentTicketInstallmentFees =
            period.installment_fees * ticketsArr[index];

          const breakdownPeriod = this.totalBreakdown.installment_fees.find(
            p => p.period_duration === period.period_duration
          );

          if (breakdownPeriod) {
            breakdownPeriod.installment_fees += currentTicketInstallmentFees;
          } else {
            this.totalBreakdown.installment_fees.push({
              ...period,
              installment_fees: currentTicketInstallmentFees
            });
          }
        });
      }

      this.totalBreakdown.grand_total_amount = this.totalBreakdown
        .current_installment_fees
        ? subTotalAmount + this.totalBreakdown.current_installment_fees
        : subTotalAmount + serviceFeesAmount + adminFeesAmount + vatTotalAmount;

      // Calculate payment method totals
      this.calculatePaymentMethodTotal(
        el,
        'cash',
        subTotalAmount,
        vatTotalAmount
      );
      this.calculatePaymentMethodTotal(
        el,
        'kiosk',
        subTotalAmount,
        vatTotalAmount
      );
      this.calculatePaymentMethodTotal(
        el,
        'meeza/digital',
        subTotalAmount,
        vatTotalAmount
      );
      this.calculatePaymentMethodTotal(
        el,
        'fawry',
        subTotalAmount,
        vatTotalAmount
      );
      this.calculatePaymentMethodTotal(
        el,
        'valu',
        subTotalAmount,
        vatTotalAmount
      );
    });
    this.breakdownService.changeTotalBreakdownInfo(this.totalBreakdown);
  }

  calculatePaymentMethodTotal(
    el: any,
    paymentMethodName: string,
    subTotalAmount: number,
    vatTotalAmount: number
  ) {
    if (el.total[paymentMethodName]) {
      const paymentMethod = el.total[paymentMethodName];
      const xpayPercentage = paymentMethod.xpay_percentage;
      const account_type = paymentMethod.account_type;
      const adminPercentage = paymentMethod.adminstrative_percentage;
      const isCalcVat = paymentMethod.is_calc_vat;
      const fixedAmount = paymentMethod.fixed_amount;
      const minAmount = paymentMethod.min_amount;
      const maxAmount = paymentMethod.max_amount;
      const discountPercentage = paymentMethod.discount_amount;

      const adminFees = this.calculateAdminFees(
        subTotalAmount,
        adminPercentage,
        account_type
      );
      const serviceFees = this.calculateServiceFees(
        subTotalAmount,
        xpayPercentage,
        isCalcVat,
        fixedAmount,
        account_type,
        vatTotalAmount,
        adminFees,
        minAmount,
        maxAmount,
        discountPercentage
      );

      this.totalBreakdown[paymentMethodName].vat_amount += vatTotalAmount;
      this.totalBreakdown[paymentMethodName].sub_total_amount = subTotalAmount;
      this.totalBreakdown[paymentMethodName].service_fees_amount = serviceFees;
      this.totalBreakdown[
        paymentMethodName
      ].administrative_fees_amount = adminFees;
      this.totalBreakdown[paymentMethodName].grand_total_amount =
        subTotalAmount + serviceFees + adminFees + vatTotalAmount;
    }
  }

  // Helper function to calculate service fees ..
  calculateServiceFees(
    subTotalAmount: number,
    xpayPercentage: number,
    isCalcVat: boolean,
    fixedAmount: number,
    accountType: string,
    vatTotalAmount: number,
    adminFeesAmount: number,
    minAmount: number,
    maxAmount: number,
    discountPercentage: number
  ): number {
    const vatRate = 1.14;
    let serviceFees = 0;
    if (accountType && accountType === 'ADDED_TO_BILL') {
      if (subTotalAmount > 0) {
        serviceFees =
          (subTotalAmount + vatTotalAmount + adminFeesAmount) * xpayPercentage +
          fixedAmount;
        serviceFees = serviceFees * (1 - discountPercentage);

        if (isCalcVat) {
          serviceFees = serviceFees * vatRate;
        }

        if (serviceFees < minAmount) {
          serviceFees = minAmount * (isCalcVat ? vatRate : 1);
        } else if (maxAmount > 0 && serviceFees > maxAmount) {
          serviceFees = maxAmount * (isCalcVat ? vatRate : 1);
        }
      }
      return serviceFees;
    }
    return serviceFees;
  }

  // Helper function to calculate admin fees .
  calculateAdminFees(
    subTotalAmount: number,
    comPercentage: number,
    accountType: string
  ): number {
    if (accountType && accountType === 'ADDED_TO_BILL') {
      const adminFees = subTotalAmount * comPercentage;
      return adminFees;
    }
    return 0;
  }

  changeTicketQuantity(event: any, i: number) {
    this.userSelectedTickets[i] = event;
    const selected_group = this.event.tickets[i].group;
    if (selected_group) {
      this.event.tickets.forEach((ticket, index) => {
        if (index !== i && ticket.group && selected_group === ticket.group) {
          this.userSelectedTickets[index] = 0;
        }
      });
    }

    this.calculateTicketsTotalChange(this.event, this.userSelectedTickets);
  }

  checkTicketsExpiration() {
    let ticketIndex = 0;
    this.ticketsExpired = !this.event.tickets.some((el, i) => {
      if (el.is_active && el.available_quantity > 0) {
        ticketIndex = i;
        return true;
      }
    });

    if (!this.ticketsExpired) {
      this.changeTicketQuantity(0, ticketIndex);
    }
  }

  bookTicket() {
    const ticketsPayload = [];
    this.event.tickets.forEach((el, i) => {
      if (
        this.userSelectedTickets[i] > 0 &&
        el.is_active &&
        el.available_quantity > 0
      ) {
        ticketsPayload.push({
          ticket_id: el.id,
          quantity: this.userSelectedTickets[i]
        });
      }
    });

    // in case all available tickts are expired
    if (ticketsPayload.length === 0) {
      return;
    }

    const eventCartInfo: EventCartInfo = {
      amount: this.breakdownService.getGrandTotalAmount(),
      currency: this.breakdownService.currentPaymentCurrency,
      tickets: ticketsPayload,
      event_id: this.event.id
    };

    // clear stored pay data
    this.payService.clearLocalStoragePayData();

    // change cart info
    this.payService.changeCartInfo<EventCartInfo>(eventCartInfo);

    // change pay type
    switch (this.currentState) {
      case PayType.EVENT:
        this.payService.changePayType(PayType.EVENT);
        break;
      case PayType.TRAINING:
        this.payService.changePayType(PayType.TRAINING);
        break;
      case PayType.SERVICE:
        this.payService.changePayType(PayType.SERVICE);
        break;
      case PayType.PRODUCT:
        this.payService.changePayType(PayType.PRODUCT);
        break;
      default:
        break;
    }

    // check for has shipping flag
    if (this.event.has_shipping_data) {
      this.payService.changeHasShippingStatus(true);
    } else {
      this.payService.changeHasShippingStatus(false);
    }
    // check for has custom fields flag
    if (this.event.custom_fields && this.event.custom_fields.length > 0) {
      this.payService.changeCustomFields(this.event.custom_fields);
    } else {
      this.payService.changeCustomFields(null);
    }

    // check for payment methods
    if (this.event.payment_methods && this.event.payment_methods.length > 0) {
      this.payService.changePaymentMethods(this.event.mappedPaymentMethods);
    } else {
      this.payService.changePaymentMethods(null);
    }

    this.payService.changeSource(this.queryParams.utm_source);

    const modeURLSegment = this.scope.mode ? `${this.scope.mode}/` : '';
    this.router.navigateByUrl(
      `${modeURLSegment}${this.scope.module}/${this.communityId}/pay`,
      {
        state: {
          verify: this.event.verify_mobile_number
        }
      }
    );
  }

  checkCheckBoxvalue(event) {
    this.checkboxFlag = event.currentTarget.checked;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  trackConversion() {
    this.payService.trackConversion();
  }
}
