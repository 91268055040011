import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private appContext = new BehaviorSubject<string>(null);
  appContext$ = this.appContext.asObservable();

  private communityHeaderVisability = new BehaviorSubject<boolean>(true);
  communityHeaderVisability$ = this.communityHeaderVisability.asObservable();

  constructor() {}

  setAppContext(context: string) {
    this.appContext.next(context);
  }

  hideCommunityHeader() {
    this.communityHeaderVisability.next(false);
  }

  showCommunityHeader() {
    this.communityHeaderVisability.next(true);
  }
}
