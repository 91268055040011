import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { GlobalErrorHandler } from '../error-handler/global-error-handler';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        const globalErrorHandler = this.injector.get(GlobalErrorHandler);

        globalErrorHandler.handleError(error);

        // if (error.status === 404) {
        //   this.router.navigate(['i', '404']);
        // }

        return throwError(globalErrorHandler.message);
      })
    );
  }
}
