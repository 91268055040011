import { Injectable } from '@angular/core';
import {
  CanLoad,
  Router,
  Route,
  UrlSegment,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { LocalStorageService } from '@core/web-storage/local-storage.service';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { User } from 'src/app/shared/interfaces/user';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate {
  currentUser: User;

  constructor(
    private authService: AuthService,
    private router: Router,
    private localStorage: LocalStorageService
  ) {
    console.log('auth guard was constructed');
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const stateUrlSegments = state.url.split('/').splice(2);
    const redirectUrl = state.url;
    console.log('inside canActivate', state);
    console.log('insdie canActivate next', next);
    console.log('inside canActivate mod', stateUrlSegments);
    if (this.localStorage.getItem('LoginType') === 'NOAUTH') {
      return true;
    } else {
      return this.authenticateUser(stateUrlSegments, redirectUrl);
    }
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    // const currentUser = this.authService.currentUserValue;

    const redirectUrlArr: Array<string> = ['', 'i'];
    segments.forEach(element => redirectUrlArr.push(element.path));

    const redirectUrl = redirectUrlArr.join('/');

    const convertedSegments: string[] = [...redirectUrlArr];
    convertedSegments.shift();
    convertedSegments.shift();
    console.log('authguard started', route);

    console.log('inside canLoad', segments);
    console.log('inside canLoad mod', convertedSegments);
    if (this.localStorage.getItem('LoginType') === 'NOAUTH') {
      return true;
    } else {
      return this.authenticateUser(convertedSegments, redirectUrl);
    }
  }

  authenticateUser(
    segments: string[],
    redirectUrl: string
    // currentUser: User
  ): boolean {
    /* this function handles the following in order
       1- check if the user is requesting to view an event(or event-like feature), direct order or variable amount
           and if so allow loading and let each feature guard handles authorization if any
       2- check if the user is logged in, if yes allow loading , if not cancel loading and go to step 3
       3- check if the requested feature is bills, there is membership value in memberhsip model from auth service,
          if yes navigate the user to bills page, if not cancel loading and navigate to login page
    */
    const currentUser = this.authService.currentUserValue;
    const currentMembership = this.authService.membershipValue;
    const authType = this.authService.authentication;

    if (
      (segments.length >= 3 && segments[2] === 'pay') ||
      (segments.length >= 3 &&
        segments[0] === 'community' &&
        (segments[2] === 'event' ||
          segments[2] === 'direct-order' ||
          segments[2] === 'training' ||
          segments[2] === 'variable-amount' ||
          segments[2] === 'var' ||
          segments[2] === 'service' ||
          segments[2] === 'nexudus' ||
          segments[2] === 'product'))
      // || segments[2] === 'bills'
    ) {
      // if the user is navigating to event/ direct-order page let the event guard handle the authentication check
      console.log(`auth guard: navigate to ${segments[2]}`);
      return true;
    } else if (currentUser) {
      console.log('auth guard: current user');
      return true;
    } else if (
      segments.length >= 3 &&
      segments[2] === 'bills' &&
      currentMembership &&
      currentMembership.communityId === segments[1] &&
      authType === 'MEMBER_ID'
    ) {
      return true;
    } else {
      console.log('auth guard: user is not logged in');
      // user is not logged in
      // navigate to login page with redirect url
      this.router.navigate(['i', 'auth'], {
        queryParams: {
          returnUrl: redirectUrl
        }
      });
      return false;
    }
  }
}
