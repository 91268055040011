import {
  Injectable,
  NgModule,
  Renderer2,
  RendererFactory2,
  Inject
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';

import {
  TRANSLOCO_LOADER,
  TRANSLOCO_CONFIG,
  Translation,
  TranslocoLoader,
  translocoConfig,
  getBrowserLang,
  TranslocoModule,
  TranslocoService
} from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';

import { environment } from '../environments/environment';
import { LocalStorageService } from './core/web-storage/local-storage.service';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

@NgModule({
  imports: [
    TranslocoLocaleModule.init({
      langToLocaleMapping: {
        en: 'en-US',
        ar: 'ar-EG'
      }
    })
  ],
  exports: [TranslocoModule, TranslocoLocaleModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en', 'ar'],
        fallbackLang: 'en',
        defaultLang: getBrowserLang().match(/en|ar/) ? getBrowserLang() : 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
        flatten: { aot: environment.production }
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
  ]
})
export class TranslocoRootModule {
  private renderer: Renderer2;

  constructor(
    private localStorage: LocalStorageService,
    private translateService: TranslocoService,
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);

    this.setCurrentActiveLang();

    this.onLangChanges();
  }

  setCurrentActiveLang() {
    const lang = this.localStorage.getItem('lang');

    if (lang) {
      this.translateService.setDefaultLang(lang);
      this.translateService.setActiveLang(lang);
    } else {
      this.translateService.setDefaultLang('en');
      this.translateService.setActiveLang('en');
      this.localStorage.setItem('lang', 'en');
    }
  }

  onLangChanges() {
    // Translation code
    this.translateService.langChanges$.subscribe((lang: string) => {
      const body = this.document.body;
      if (lang === 'ar') {
        this.document.dir = 'rtl';
        this.document.documentElement.lang = 'ar';
        this.renderer.addClass(body, 'rtl');
      } else {
        this.document.dir = 'ltr';
        this.document.documentElement.lang = 'en';
        this.renderer.removeClass(body, 'rtl');
      }
      this.localStorage.setItem('lang', lang);
    });
  }
}
