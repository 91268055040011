import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

type RawErrors = { [key: string]: string }[];

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return 'No Internet Connection';
    }
    return error.message ? error.message : error.toString();
  }

  getClientStack(error: Error): string {
    return error.stack;
  }

  getServerMessage(error: HttpErrorResponse): string {
    if (error.status === 500) {
      return 'internal server error';
    } else if (error.error.status) {
      const rawErrors: RawErrors = error.error.status.errors;
      let errorMessage = '';

      if (Array.isArray(rawErrors)) {
        for (let i = 0; i < rawErrors.length; i++) {
          errorMessage += JSON.stringify(rawErrors[i])
            .replace(/['"{}\[\]]/g, '')
            .replace(':', ': ');
          if (i !== rawErrors.length - 1) {
            errorMessage += '\n';
          }
        }

        return errorMessage;
      }
      return error.message;
    } else {
      return 'An error has happened';
    }
  }
}
