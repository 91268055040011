export enum PayStepAction {
  NEXT = 'next',
  SUBMIT = 'submit'
}

export enum PayStepName {
  BILLING = 'billing-info',
  VERIFIED = 'verify-mobile-number',
  CUSTOM = 'custom-fields-info',
  SHIPPING = 'shipping-info',
  INSTALLMENT = 'installment-info',
  FINAL = 'card-transaction-info'
}

export interface PayStep {
  name: PayStepName;
  allowed: boolean;
  action: PayStepAction;
  step: number;
}

export interface CurrentPayStep {
  name: PayStepName;
  index: number;
}
