import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from './layout/layout.module';
import { TranslocoRootModule } from './transloco-root.module';
import * as Mixpanel from 'mixpanel-browser';
import { environment } from '../environments/environment';

import { AppComponent } from './app/app.component';
import { RouterHistoryService } from '@core/router-history/router-history.service';

Mixpanel.init(`${environment.MixPanel_ProjectToken}`, { debug: true, track_pageview: true, persistence: 'localStorage' })

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,

    CoreModule,
    SharedModule,
    LayoutModule,

    TranslocoRootModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(routerHistoryService: RouterHistoryService) {} // Just to make sure an instance of the service is initialized
}
