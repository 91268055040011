export enum FieldType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  CHECKBOX = 'CHECKBOX'
}

export interface CustomField {
  id: number;
  field_label: string;
  field_type: FieldType;
  field_choices?: string;
  help_text: string;
  required: boolean;
}

export const CustomFieldTypeMap = {
  TEXT: {
    mainType: 'input',
    subType: 'text'
  },
  NUMBER: {
    mainType: 'input',
    subType: 'number'
  },
  CHECKBOX: {
    mainType: 'checkbox',
    subType: null
  },
  DROPDOWNLIST: {
    mainType: 'select',
    subType: null
  }
};

export interface CustomFieldPayload {
  field_id?: number;
  field_label: string;
  field_value: any;
}
