import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  logError(message: string, stack: string, error: Error | HttpErrorResponse) {
    if (environment.production || environment.ENV_NAME === 'staging') {
      // Sentry
      if (error instanceof HttpErrorResponse) {
        Sentry.captureException(message);
      } else {
        Sentry.captureException(error);
      }
    }

    let errMessage = message;
    if (error instanceof HttpErrorResponse) {
      errMessage = message.split('\n').join(' - ');
    }

    console.error(`LoggingService: ${errMessage}`);
  }
}
