import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SpinnerService } from '../spinner.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnDestroy {
  @Input() message = '';
  loading = false;
  loadingSubscription: Subscription;

  constructor(private spinnerService: SpinnerService) {}

  ngOnInit() {
    this.loadingSubscription = this.spinnerService.loading$.subscribe(
      (loadingStatus: boolean) => {
        this.loading = loadingStatus;
      }
    );
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }
}
