import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { ApiService } from 'src/app/core/api/api.service';
import { Membership } from '../../interfaces/user';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { XpayService } from 'src/app/features/community/shared/xpay.service';

@Component({
  selector: 'app-otp-login',
  templateUrl: './otp-login.component.html',
  styleUrls: ['./otp-login.component.scss']
})
export class OtpLoginComponent implements OnInit {
  membershipIdForm: FormGroup;
  returnUrl: string;
  communityId: string;

  formError: boolean;
  submitted: boolean;

  @Output() submitEvent = new EventEmitter<string>();

  constructor(
    private fb: FormBuilder,
    // private route: ActivatedRouteSnapshot,
    private api: ApiService,
    private communityService: XpayService
  ) {}

  get f() {
    return this.membershipIdForm;
  }

  get membershipControl() {
    return this.f.controls.membership_id;
  }

  ngOnInit() {
    // this.returnUrl = this.route.snapshot.queryParams.returnUrl || '';
    // console.log('returnUrl', this.returnUrl);
    // const urlSegments = this.returnUrl.split('/');
    // console.log(urlSegments);
    // this.communityId = +urlSegments[2];
    // this.communityId = +this.route.parent.paramMap.get('communityid');
    this.communityId = this.communityService.communityId;
    this.initDonationForm();
  }

  initDonationForm() {
    this.membershipIdForm = this.fb.group({
      membership_id: []
    });
  }

  pay() {
    this.submitted = true;
    console.log(this.membershipControl.value.membership_id);

    this.validate().subscribe(
      membership => {
        if (membership) {
          this.submitEvent.emit(this.membershipControl.value.membership_id);
        }
      },
      err => {
        this.formError = true;
        this.membershipControl.setErrors({ required: true });
      }
    );
  }

  validate() {
    // if (this.membershipControl.value.membership_id) {
    return this.api.get<Membership>(
      `communities/${this.communityId}/memberships/${this.membershipControl.value.membership_id}/`
    );
    //     .pipe(
    //       map(res => (res ? null : { notFound: true })),
    //       catchError(() => of({ notFound: true }))
    //     );
    // } else {
    //   return of({ notFound: true });
    // }
  }
}
