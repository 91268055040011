import { BillingInfo } from './billingInfo';

export interface CardToken {
  id: number;
  masked_pan: string;
  subtype: 'VISA' | 'MASTERCARD';
}
export type NewCard = null;
export type Installment = null;
export type Fawry = 'Fawry';
export type valu = 'valu';
export type CashOnDelivery = 'CashOnDelivery';
export type Kiosk = 'Kiosk';
export type Upg = 'Meeza/Digital';
export type PaymentMethodValue =
  | CardToken['id']
  | CashOnDelivery
  | NewCard
  | Kiosk
  | valu
  | Upg;

export enum PaymentMethodType {
  NEWCARD = 'newCard',
  CARDTOKEN = 'cardToken',
  CASHONDELIVERY = 'cashOnDelivery',
  VALU = 'valu',
  KIOSK = 'kiosk',
  UPG = 'meeza/digital',
  INSTALLMENT = 'installment',
  FAWRY = 'fawry'
}

export interface PaymentMethodConfig {
  type: PaymentMethodType;
  value: PaymentMethodValue;
}

export interface PaymentFirstStep {
  billingInfo: BillingInfo;
  paymentMethodConfig: PaymentMethodConfig;
}

export interface InstallmentPeriod {
  duration: number;
  xpay_interest: number;
  bank_interest: number;
  xpay_interest_type: string;
  bank_interest_type: string;
  interest: number;
}
