import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { PaymentModule } from '@shared/interfaces/paymentModule';
import { LocalStorageService } from '@core/web-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentModuleService {
  private paymentModule = new BehaviorSubject<PaymentModule>(null);
  paymentModule$ = this.paymentModule.asObservable();

  constructor(private localStorageService: LocalStorageService) {}

  changePaymentModule(paymentModule: PaymentModule) {
    // set payment module in local storage
    this.localStorageService.setItem('pmd', paymentModule);

    this.paymentModule.next(paymentModule);
  }

  public get currentPaymentModule(): PaymentModule | null {
    // In Memory payment module value
    const imPaymentModuleValue = this.paymentModule.getValue();

    // Local Storage payment module value
    const lsPaymentModuleValue = this.localStorageService.getItem('pmd');

    // check if in memory payment module value is there and if not
    if (imPaymentModuleValue) {
      return imPaymentModuleValue;

      // check if local storage payment module value is there and if not
    } else if (lsPaymentModuleValue) {
      return lsPaymentModuleValue;

      // return null
    } else {
      return null;
    }
  }

  clearLocalStoragePaymentModuleData() {
    const localstorageItems = ['pmd'];

    // clear payment module data / pmd

    localstorageItems.forEach(el => this.localStorageService.removeItem(el));
  }
}
